.dropdown-container-label-btn {
    margin: 0;
//     margin-bottom: 1em;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    @include media($extra-small-only) {
        width: 100%;
        max-width: 400px;
    }
}

.dropdown-container-label-btn > i {
    margin-right: 5px;
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    margin-bottom: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

:has(.dropdown-container-input:checked) .dropdown-container-label-btn > i {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: -5px;
}

.dropdown-container {
    margin-top: 15px;
    clear:both;
    width:100%;
    height:0px;
    overflow: hidden;
    text-align: center;
    transition: height .4s ease;
}

.dropdown-container-input {
    position: absolute; 
    opacity: 0; 
    height: 0px;
}    

.dropdown-container-input:checked + .dropdown-container {
    height: 80px;
} 
