.l-sales-page-section {
    margin-top: 70px;
    margin-bottom: 70px;

    font-size: $sales-page-section-font-size;
}

.l-sales-page-section__container {
    @include container-text-optimized();
}

.l-sales-page-section__title {
    margin: 0;
    margin-bottom: 30px;

    font-size: 1.7em;
    font-weight: 400;
    text-align: center;
}
