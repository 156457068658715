
.c-small-input-group {
    display: flex;
    align-content: center;
    align-items: center;
}

.c-small-input-group > .c-labeled-input {
    margin-right: 1em;
}

.c-small-input-group > .c-labeled-input:last-child {
    margin-right: 0px;
}

.c-small-input-group > .c-button {
    height: 34px;
    margin-top: 5px;
}
