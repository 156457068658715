.l-central-block-page {
    margin-bottom: 30px;
    background-color: $simple-page-bg;
}

.l-central-block-page__container {
    display: block;
    margin: auto;
    padding: 0 10px;
    padding-top: 10%;
}
