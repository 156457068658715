.l-page-headline {
    margin-top: 35px;
    margin-bottom: 35px;

    text-align: left;
}

.l-page-headline-small {
    margin-top: 1em;
    margin-bottom: 1em;

    text-align: left;
}

.l-page-headline__breadcrumb {
    display: none;
    margin-top: 20px;

    @include media($medium) {
        display: block;
    }

}

.l-page-headline__title {
    margin: 0;

    color: $page-headline-title-color;

    font-weight: 400;
    font-size: $heading2-font-size;

    @include media($medium) {
        font-size: $heading1-font-size;
    }
}

.l-page-headline-small__title {
    margin: 0;

    color: $page-headline-title-color;

    font-weight: 400;
    font-size: $heading2-font-size;
}

.l-page-headline-info {
    font-size: 1.17em;
}

.l-page-headline-info__title {
    margin: 0;
    font-size: 1.17em;
    color: $page-headline-title-color;
    font-weight: 400;
}
