.l-receipt {
    @include container;

    margin-bottom: 25px;
    margin-top: 25px;

    color: $receipt-text-color;

    font-size: $receipt-font-size;

    @include media($small) {
        padding-left: 20%;
        padding-right: 20%;
    }
}

.l-receipt__text {
    h1 {
        margin: 40px 0;

        //color: #333;

        font-size: 3em;
        font-weight: 400;

        text-align: center;
    }

    p {
        color: $receipt-paragraph-color;

        font-size: 2em;
        font-weight: 300;
    }
}

.l-receipt__action {
    margin-top: 35px;

    text-align: center;
}
