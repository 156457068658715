.l-our-clients {
    padding-top: 70px;
    padding-bottom: 70px;

    font-size: $our-clients-font-size;
}

.l-our-clients__title {
    margin: 0;
    margin-bottom: 40px;

    color: $our-clients-title-color;

    font-size: 3em;
    font-weight: 400;

    text-align: center;
}
