.l-forum-question {
    margin-top: 35px;
    margin-bottom: 35px;
    max-width: 800px;
    min-height: 300px;

    text-align: left;
}

.l-forum-question__breadcrumb {
    display: none;
    margin-top: 20px;

    @include media($medium) {
        display: block;
    }

}

.l-forum-question__alert {
    margin-bottom: 20px;
}

.l-forum-question__title {
    margin: 0;

    color: $forum-question-title-color;

    font-weight: 400;
    font-size: $heading2-font-size;

    @include media($medium) {
        font-size: $heading1-font-size;
    }
}

.l-forum-question__label-container {
    margin-top: 10px;

    font-size: $heading2-font-size * 0.6;
    font-weight: 300;
}

.l-forum-question__label {
    padding: 3px 8px;
    border-radius: 0px;

    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
    }

    &.is-solved {
        background-color: $forum-question-solved-label-bg-color;
        color: $forum-question-solved-label-text-color;
    }

    &.is-solved.is-updatable:hover {
        background-color: $forum-question-unsolved-label-bg-color;
        color: $forum-question-unsolved-label-text-color;
    }

    &.is-unsolved {
        background-color: $forum-question-unsolved-label-bg-color;
        color: $forum-question-unsolved-label-text-color;
    }

    &.is-unsolved.is-updatable:hover {
        background-color: $forum-question-solved-label-bg-color;
        color: $forum-question-solved-label-text-color;
    }
}

.l-forum-question__messages {
    margin: 0;
    padding: 0;

    list-style: none;
}
