.c-payment-icon {
    position: relative;

    display: inline-block;
    width: 80px;
    height: 50px;
    border-radius: 0.21em;
    border: 1px solid rgba(0, 0, 0, 0.075);
    margin: 0.5em;

    font-size: 1.4rem;
}

.c-payment-icon--visa {
    background: #ffffff url(/images/visa.png);
}

.c-payment-icon--mastercard {
    background: #ffffff url(/images/mastercard.png);
}

.c-payment-icon--amex {
    background: #ffffff url(/images/amex.png);
}

.c-payment-icon--bank-slip {
    background: #ffffff url(/images/bank-slip.png);
}

.c-payment-icon__text {
    display: block;

    text-indent: -9999px;
}

.c-payment-icon__label {
    position: absolute;
    top: -0.5em;
    right: -0.5em;

    padding: 0.2em 0.3em;
    border-radius: 0.21em;

    background-color: $payment-icon-label-bgcolor;
    color: #fff;

    font-weight: bold;
}
