.c-video-navigator {
}

.c-video-navigator__arrow {
    position: fixed;
    top: 50%;
    z-index: 9000;

    display: none;
    margin-top: -25px;
    width: 30px;
    height: 50px;

    background: url(/images/video-navigation.png);

    text-indent: -9999px;

    opacity: .5;
    -webkit-transition: opacity 0.5s ease 0s;
    -moz-transition: opacity 0.5s ease 0s;
    -ms-transition: opacity 0.5s ease 0s;
    -o-transition: opacity 0.5s ease 0s;
    transition: opacity 0.5s ease 0s;

    &:hover {
        opacity: 1;
    }

    .c-video-navigator--next & {
        right: 10px;

        background-position: 0px 0px;
    }

    .c-video-navigator--previous & {
        left: 10px;

        background-position: 30px 0px;
    }

    @include media($medium) {
        display: block;
    }
}

.c-video-navigator__card {
    position: fixed;
    top: 50%;
    z-index: 9000;

    display: block;
    overflow: hidden;
    margin-top: -60px;
    height: 120px;
    width: 0px;

    color: #ffffff;
    background-color: #0a0a0a;

    opacity: .8;
    -webkit-transition: width 0.5s ease 0.5s;
    -moz-transition: width 0.5s ease 0.5s;
    -ms-transition: width 0.5s ease 0.5s;
    -o-transition: width 0.5s ease 0.5s;
    transition: width 0.5s ease 0.5s;

    .c-video-navigator--next & {
        right: 0;

        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .c-video-navigator--previous & {
        left: 0;

        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .c-video-navigator:hover & {
        width: 300px;
    }

}

.c-video-navigator__card-container {
    display: block;
    width: 240px;
    padding: 10px 8px;

    .c-video-navigator--previous & {
        margin-left: 54px;
    }

    .c-video-navigator--next & {
        margin-left: 6px;
    }
}

.c-video-navigator__label {
    display: block;

    font-size: 12px;
    text-shadow: 0px 1px 2px #000;
}

.c-video-navigator__title {
    display: block;

    font-size: 18px;
}
