.c-link {
    color: #fff;

    //font-size: 1.4rem;
    text-decoration: none;

    &:hover, &:focus {
        color: #fff;

        text-decoration: underline;
    }
}
