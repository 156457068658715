.l-course-headline {
    padding-top: 55px;
    padding-bottom: 55px;

    background-color: $course-headline-bgcolor;

    font-size: $course-headline-font-size;
    text-align: center;
}

.l-course-headline__title {
    margin: 0;

    font-weight: 400;
    font-size: $heading2-font-size;

    @include media($small) {
        font-size: $heading1-font-size;
    }
}

.l-course-headline__subtitle {
    margin: 10px 0;

    font-weight: normal;
    font-size: 1.3em;

    color: $course-headline-subtitle-text-color;
}

.l-course-headline__instructors {
    margin: 25px 0;

    font-weight: normal;
    font-size: 1em;

    color: $course-headline-instructores-text-color;
}

.l-course-headline__progress {
    margin: 0 auto;

    @include media($small) {
        width: 70%;
    }
}

.l-course-headline__certificate {
    margin-top: 20px;
}
