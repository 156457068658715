.c-course-card {
    position: relative;

    display: block;
    padding: 0px;
    border: 1px solid $course-card-border-color;

    font-size: $course-card-font-size;

    &:hover, :focus {
        box-shadow: 0 1px 5px $course-card-shadow-color;

        opacity: 0.9;
    }
}

.c-course-card__container {
    display: block;

    text-decoration: none;

    &:hover, &:focus {
        text-decoration: none;
    }

}

.c-course-card__thumb {
    width: 100%;
    height: 100%;
}

.c-course-card__thumb > img {
    display: block;
    width: 100%;
}

.c-course-card__annotation {
    position: absolute;
    top: 5px;
    left: 5px;

    display: block;
    padding: 3px 5px;
    border-radius: 2px;

//     background: $course-card-annotation-bgcolor;
    color: $course-card-annotation-text-color;

    font-size: 0.8461em; // 11px
    font-weight: normal;
}

.c-course-card__course-completed {
    font-size: 2em;
    margin-right: 5px;
}

.c-course-card__course-lock {
    font-size: 2em;
    margin-right: 5px;
}

.c-course-card__details {
    display: block;
    padding: 15px;

    //background-color: yellow;
}

.c-course-card__title {
    display: block;

    margin: 0;
//     margin-bottom: 5px;

    color: $course-card-title-color;

    font-size: 1.1em;
    font-weight: 400;

    @include media($extra-small-only) {
        font-size: 1.3em;
    }

}

.c-course-card__title--fixed-height {
    @include prefixer-value(display, box, webkit moz ms o);
    @include prefixer(line-clamp, 2, webkit moz o ms spec);
    @include prefixer(box-orient, vertical, webkit moz ms o spec);

    height: 2.4em;

    overflow: hidden;
    text-overflow: ellipsis;
}

.c-course-card__extra-info {
    color: $course-card-extra-info-color;

    font-size: 1em; // 14px
}

.c-course-size-info {
    margin: 5px 0px;
    height: 1.2em;
}

.c-course-card__description {
    margin: 0;

    color: $course-card-description-color;

    font-size: 1em;
}

.c-course-card__ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    font-size: 0.8em;
}

.c-course-card__ribbon span {
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: $course-card-ribbon-normal-color;
    background: linear-gradient($course-card-ribbon-normal-color 0%, $course-card-ribbon-dark-color 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
}

.c-course-card__ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid $course-card-ribbon-dark-color;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid $course-card-ribbon-dark-color;
}

.c-course-card__ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid $course-card-ribbon-dark-color;
    border-bottom: 3px solid transparent;
    border-top: 3px solid $course-card-ribbon-dark-color;
}
