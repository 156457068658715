.l-sales-page-heading {
    padding-top: 70px;
    padding-bottom: 70px;

    font-size: $sales-page-heading-font-size;
    text-align: center;

    background: $sales-page-heading-bgcolor;
    border-bottom: 1px solid $sales-page-heading-border-color;
}

.l-sales-page-heading__title {
    margin: 0;
    margin-bottom: 20px;

    color: $sales-page-heading-title-color;

    font-size: 2.125em;
    font-weight: 400;
}

.l-sales-page-heading__subtitle {
    margin: 0;
    margin-bottom: 60px;

    color: $sales-page-heading-subtitle-color;

    font-size: 1em;
    font-weight: 300;
}

.l-sales-page-heading__content {

    .c-button--purchase {
        margin-top: 30px;
    }
}
