.c-breadcrumb {
    display: block;
}

.c-breadcrumb__path + .c-breadcrumb__path {
    margin-left: 10px;

    &::before {
        display: inline-block;
        width: 5px;
        height: 8px;
        margin-right: 10px;

        background: url("/images/breadcrumb-arrow.png") no-repeat;

        content: "";
    }
}

.teste1 {

        padding: 4px 8px;
        border-radius: 2px;

        font-size: 0.9em;


        border: 1px solid $_light-gray;
        color: $_dark-gray;

}
