.c-forum-questions {
    font-size: $forum-questions-font-size;
}

.c-forum-questions__list {
    padding: 0;

    list-style-type: none;
}

.c-forum-questions__buttons {
    margin: 15px 5px 4px;
}

.c-forum-questions__feedback {
    padding-top: 20px;
    text-align: center;

    color: #6b6b6b;
}

.c-forum-questions__item {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid $forum-questions-item-line-color;

    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.c-forum-questions__summary {
    flex: 600px;
    align-self: flex-start;
    margin-left: 15px;
}

.c-forum-questions__author-picture {
    flex: 0 50px;
}

.c-forum-questions__avatar {
    border-radius: 50%;
}

.c-forum-questions__title {
    margin: 0;
    margin-bottom: 2px;

    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    a {
        color: $forum-questions-title-color;
    }

    a:hover, a:focus {
        color: $forum-questions-title-hover-color;

        text-decoration: none;
    }
}

.c-forum-questions__author {
    color: $forum-questions-author-date-color;

    font-size: 0.9em;
}

.c-forum-questions__author--update::before {
    content: "- ";
}

.c-forum-questions__path {
    margin-top: 15px;

    color: $forum-questions-author-date-color;

    font-size: 0.9em;
}

.c-forum-questions__label {
    padding: 3px 8px;
    border-radius: 0px;

    font-size: 0.7em;
    font-weight: 500;
    text-transform: uppercase;

    &.is-solved {
        background-color: $forum-questions-label-bgcolor;
        color: $forum-questions-label-color;
    }
    
    &.info {
        background-color: $forum-questions-label-secondary-bgcolor;
        color: $forum-questions-label-secondary-color;
    }
    
    &.is-unsolved {
        background-color: $forum-question-unsolved-label-bg-color;
        color: $forum-question-unsolved-label-text-color;
    }
}

.c-forum-questions__author-picture--vip:after {
    position: relative;
    left: 25px;
    bottom: 15px;

    padding: 2px 5px;

    font-size: 0.75em;
    font-weight: 500;
    text-transform: uppercase;

    background-color: $forum-questions-vip-label-bgcolor;
    color: $forum-questions-vip-label-color;

    content: "VIP";
}

.c-forum-questions__post-date {
    color: $forum-questions-author-date-color;

    font-size: 0.9em;

    &:before {
        content: "- ";
    }
}

.c-forum-questions__item + .c-forum-questions__item {
    margin-top: 15px;
}

.c-forum-questions--larger {
    .c-forum-questions__item {
        margin-top: 25px;
        padding-bottom: 25px;
    }
}
