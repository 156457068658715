.c-input {
    display: block;
    width: 100%;
    //height: 34px;
    padding: 6px 12px;
    font-size: $input-font-size;

    color: $input-text-color;
    background-color: #fff;
    background-image: none;
    border: 1px solid $input-border-color;
    border-radius: 4px;

    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

}

.c-input:disabled {
    background-color: #efefef;
}

input.c-input:read-only {
    background-color: #afafaf21;
}

input.c-input {
    height: 34px;
}

select.c-input {
    height: 34px;
    -webkit-appearance: menulist
}

.c-input--big {
    height: 45px;
}

textarea.c-input {
    //height: auto;
    font-size:  1.7rem;
}

textarea.c-input--tall {
    height: 200px;
}

textarea.c-input--small {
    font-size:  1.4rem;
}

.c-input--error {
    border: 1px solid $error-color;
}
