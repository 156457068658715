@mixin prefixer-value(
    $property,
    $value,
    $prefixes: ()
) {

    @each $prefix in $prefixes {
        #{$property}: #{"-" + $prefix + "-" + $value};
    }
    #{$property}: #{$value};
}
