.c-description-list {
    dl {
        border: 3px double #ccc;
        padding: 0.5em;
    }
    dt {
        float: left;
        clear: left;
        width: 150px;
        text-align: left;
        font-weight: bold;
        color: black;
    }
    dt::after {
        content: ":";
    }
    dd {
        margin: 0 0 0 110px;
        padding: 0 0 0.5em 0;
    }
}
