// Sales page section
$sales-page-section-font-size: 1.6rem;

// Sales page heading
$sales-page-heading-font-size: 1.6rem;
$sales-page-heading-bgcolor: $_lighter-gray;
$sales-page-heading-border-color: $_light-gray;
$sales-page-heading-title-color: $_darker-gray;
$sales-page-heading-subtitle-color: $_dark-gray;

// Footer
$footer-bgcolor: $_darker-gray;
$footer-font-size: 1.3rem;
$footer-text-color: #ffffff;
$footer-link-color: $footer-text-color;
$footer-link-hover-color: $_normal-gray;

// Header
$header-bgcolor: $_brand-color;
$header-text-color: #ffffff;
$header-shadow-color: $_dark-gray;

// Home headline
$home-headline-font-size: 4rem;
$home-headline-title-color: $base-text-color;
$home-headline-subtitle-color: $_dark-gray;

// Our clients
$our-clients-font-size: 1rem;
$our-clients-title-color: $base-text-color;

// Receipt
$receipt-font-size: 1rem;
$receipt-text-color: $base-text-color;
$receipt-paragraph-color: $_dark-gray;

// Page headline
//$page-headline-font-size: $heading1-font-size;
$page-headline-title-color: $base-text-color;

// Forum question
$forum-question-title-color: $base-text-color;
$forum-question-solved-label-bg-color: #058205;
$forum-question-solved-label-text-color: #fff;

$forum-question-unsolved-label-bg-color: $_dark-gray;
$forum-question-unsolved-label-text-color: #fff;

// Course headline
$course-headline-bgcolor: $_light-gray;
$course-headline-font-size: 1.3rem;
$course-headline-subtitle-text-color: $_dark-gray;
$course-headline-instructores-text-color: $_dark-gray;

// No data
$no-data-container-bgcolor: $_lighter-gray;


// Simple page
$simple-page-bg: #f6f6f6;
