.o-text-content {
    max-width: 800px;

    text-align: justify;

    p, h1, h2, h3, h4 {
        & > code {
            @include inline-code;
        }
    }
}
