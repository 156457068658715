@mixin inline-code() {
    padding: 2px 3px 1px;
    border: 1px solid $code-border-color;
    border-radius: .2rem;

    background: $code-bg-color;
    color: $code-font-color;

    font-size: 1.2em;
}
