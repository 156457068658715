.c-pagination {
    display: inline-block;
}

.c-pagination__group {
    text-align: center;
}

.c-pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
    margin: 0 4px;
    cursor: pointer;
}

.c-pagination a.disabled {
    text-decoration: none;
    border: 1px solid #ddd;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}

.c-pagination a.active {
    background-color: $pagination-active-button-color;
    color: white;
    border: 1px solid darken($pagination-active-button-color, 12%);
}

.c-pagination a:hover:not(.active) {
    background-color: $pagination-inactive-button-color;
}


.c-pagination__number {
    @include media($extra-small-only) {
        display: none;
    }
}
