.c-user-dropdown {
    position: relative;

    padding: 10px 0;

    font-size: $user-dropdown-font-size;
}

.c-user-dropdown__avatar {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.c-user-dropdown__menu-box {
    position: absolute;
    z-index: 1500;
    top: 100%;
    left: inherit;
    right: 0;

    display: none;
    width: 240px;
    margin: 0;
    border-top: 1px solid $user-dropdown-border-color;
    box-shadow: 0 2px 5px $user-dropdown-border-color;

    background-color: #ffffff;
    color: $base-text-color;

    &:before {
        top: -10px;

        border-color: transparent transparent $user-dropdown-border-color;
    }

    &:after {
        top: -8px;

        border-color: transparent transparent #fff;
    }

    &:before, &:after {
        position: absolute;
        right: 12px;

        box-sizing: border-box;
        height: 0;
        width: 0;
        border-style: solid;
        border-width: 0 7px 10px;

        content: ""
    }

}

.c-user-dropdown__menu-box--visible {
    display: block;
}

.c-user-dropdown__menu-list {
    margin: 0;
    padding: 8px 0;
    border-top: 1px solid $user-dropdown-border-color;

    list-style: none;
}


.c-user-dropdown__menu-item {

    &:hover {
        background-color: $user-dropdown-item-hover-bg-color;

        a {
            color: $user-dropdown-item-hover-text-color;
        }
    }

    a {
        display: block;
        padding: 8px 12px;

        color: $user-dropdown-item-text-color;

        text-decoration: none;
    }

}

.c-user-dropdown__user {
    padding: 8px 12px;
}

.c-user-dropdown__user-name {
    display: block;
    overflow: hidden;

    font-size: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.c-user-dropdown__user-email {
    display: block;
    overflow: hidden;

    font-size: 0.8em;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: $user-dropdown-email-text-color;
}
