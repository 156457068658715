.c-clients {
    margin: 0;
    padding: 0;

    text-align: center;

    list-style: none;

    @include media($small) {
        margin: 0 18%;
    }

}

.c-clients__logo {
    display: inline-block;
    margin: 20px;

    @include prefixer(filter, grayscale(100%), webkit moz ms o spec);
}
