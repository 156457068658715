.survey {
  font-size: 2em;
  justify-content: center;
  display: flex;
}

.survey > .survey-score {
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 1.6em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  display: inline-table;
}
.survey > .survey-score > small {
  margin-top: 0.3em;
  font-size: 0.4em;
  display: block;
  text-align: center;
}

.survey > .survey-score > input[type="radio"] {
  display: none !important;
}

.survey > .survey-score > input[type="radio"]:checked ~ * .circle { 
  color: white;
}

.survey .circle {
  border-radius: 1.6em;
  border-style: solid;
  display: inline-block;
  font-weight: bold;
  line-height: 1.4em;
  text-align: center;
  width: 1.6em; 
  color: black;
  border-color: black;
}

.survey .circle:hover {
  cursor: pointer;
}

.survey .circle-red {
  border-color: red;
  color: red;
}

.survey .circle-orange {
  border-color: orange;
  color: orange;
}

.survey .circle-yellow {
  border-color: #f4f400;
  color: #f4f400;
}

.survey .circle-light-green {
  border-color: #00d153;
  color: #00d153;
}

.survey .circle-green {
  border-color: #00ae32;
  color: #00ae32;
}

.survey > .survey-score > input[type="radio"]:checked ~ * .circle-red {
  background-color: red;
  color: white;
}
.survey > .survey-score > input[type="radio"]:checked ~ * .circle-orange {
  background-color: orange;
  color: white;
}
.survey > .survey-score > input[type="radio"]:checked ~ * .circle-yellow {
  background-color: #f4f400;
  color: white;
}
.survey > .survey-score > input[type="radio"]:checked ~ * .circle-light-green {
  background-color: #00d153;
  color: white;
}
.survey > .survey-score > input[type="radio"]:checked ~ * .circle-green {
  background-color: #00ae32;
  color: white;
}

.survey .circle-red:hover {
  background-color: red;
  color: white;
}
.survey .circle-orange:hover {
  background-color: orange;
  color: white;
}
.survey .circle-yellow:hover {
  background-color: #f4f400;
  color: white;
}
.survey .circle-light-green:hover {
  background-color: #00d153;
  color: white;
}
.survey .circle-green:hover {
  background-color: #00ae32;
  color: white;
}
.survey-comment {
  margin-top: 2em;
}
.survey-submit {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
}

.survey .circle-dark-red {
  border-color: #c40000;
  color: #c40000;
}
.survey > .survey-score > input[type="radio"]:checked ~ * .circle-dark-red {
  background-color: #c40000;
  color: white;
}
.survey .circle-dark-red:hover {
  background-color: #c40000;
  color: white;
}

.blocker {
    background-color: rgba(0,0,0,0.55) !important;
}