.l-courses-list {
    @include container;
}

.l-courses-list--my-courses {
//     margin-top: 35px;
//     margin-bottom: flex-gutter(12) * -1;

    @include media($extra-small-only) {
//         margin-bottom: -15px;
    }
}

.l-courses-list__card {
    margin-bottom: 1em;

    @include media($extra-small-only) {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;
        min-width: 240px;
        margin-bottom: 3em;
    }

    @include media($small-only) {
        @include span-columns(3);
        @include omega(4n);
    }

    @include media($medium) {
        @include span-columns(3);
        @include omega(5n);
        width: 18.7%;
    }


}

.l-courses-list__card-details {
    @include media($small) {
        height: 130px;
        overflow: hidden;
    }
}



/*
.l-courses-list {
    background-color: red;
    @include container;

    margin-top: 35px;
    margin-bottom: flex-gutter(12) * -1;

    @include media($extra-small-only) {
        margin-bottom: -15px;
    }
}


.l-courses-list__card {
    margin-bottom: flex-gutter(12);

    @include media($extra-small-only) {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;
        min-width: 280px;
        margin-bottom: 15px;
    }

    @include media($small-only) {
        @include span-columns(6);
        @include omega(2n);
    }

    @include media($medium) {
        @include span-columns(4);
        @include omega(3n);
    }

}
*/
