$parent-columns: $grid-columns !default;
$fg-column: $column;
$fg-gutter: $gutter;
$fg-max-columns: $grid-columns;
$container-display-table: false !default;
$layout-direction: LTR !default;

@function flex-grid($columns, $container-columns: $fg-max-columns) {
  $width: flex-width($columns);
  $container-width: flex-width($container-columns);
  @return percentage($width / $container-width);
}

@function flex-gutter($container-columns: $fg-max-columns, $gutter: $fg-gutter) {
  $container-width: flex-width($container-columns);
  @return percentage($gutter / $container-width);
}

@function flex-width($n, $column-width: $fg-column, $gutter-width: $fg-gutter) {
  @return $n * $column-width + ($n - 1) * $gutter-width;
}

@function get-parent-columns($columns) {
  @if $columns != $grid-columns {
    $parent-columns: $columns !global;
  } @else {
    $parent-columns: $grid-columns !global;
  }

  @return $parent-columns;
}

@function is-display-table($container-is-display-table, $display) {
  @return $container-is-display-table == true or $display == table;
}
