.c-labeled-input {
    margin-bottom: 15px;

    label {
        display: block;
        margin-bottom: 5px;
        text-align: left;
        @include clearfix;
    }

}
