.c-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .c-tooltip .c-tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 4px 4px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 1s;
    font-size: 0.6em;
  }
  
  .c-tooltip:hover .c-tooltiptext {
    visibility: visible;
    opacity: 1;
  }
