@import "settings/_global";

@import "bourbon";
@import "neat";
@import "font-awesome";

// Used with preprocessors and contain font, colors definitions, etc.
@import "settings/_components.scss";
@import "settings/_global.scss";
@import "settings/_layout.scss";
@import "settings/_objects.scss";
@import "settings/_responsive.scss";

// Globally used mixins and functions.
// It’s important not to output any CSS in the first 2 layers.
@import "tools/_containers.scss";
@import "tools/_images.scss";
@import "tools/_prefixer.scss";
@import "tools/_text.scss";

// Reset and/or normalize styles, box-sizing definition, etc.
// This is the first layer which generates actual CSS.
@import "generic/_typography.scss";

// Styling for bare HTML elements (like H1, A, etc.).
// These come with default styling from the browser so we can redefine them here.
@import "elements/_body.scss";
@import "elements/_links.scss";

// Class-based selectors which define undecorated design patterns,
// for example media object known from OOCSS
@import "objects/_container.scss";
@import "objects/_heading.scss";
@import "objects/_list.scss";
@import "objects/_source-code.scss";
@import "objects/_text-content.scss";
@import "objects/_text.scss";
@import "objects/_user-message.scss";

// Specific UI components.
// This is where majority of our work takes place and
// our UI components are often composed of Objects and Components
@import "components/_accordion.scss";
@import "components/_alert.scss";
@import "components/_arrow.scss";
@import "components/_breadcrumb.scss";
@import "components/_button.scss";
@import "components/_checkout-coupon.scss";
@import "components/_checkout-summary.scss";
@import "components/_clients.scss";
@import "components/_countdown.scss";
@import "components/_coupon-input.scss";
@import "components/_course-card.scss";
@import "components/_course-curriculum.scss";
@import "components/_course-instructor.scss";
@import "components/_course-sales-curriculum.scss";
@import "components/_dialog-swal.scss";
@import "components/_disclaimer-text.scss";
@import "components/_dropdown-container-btn.scss";
@import "components/_dropdown-container.scss";
@import "components/_dropdown.scss";
@import "components/_error-panel.scss";
@import "components/_fieldset.scss";
@import "components/_forum-message.scss";
@import "components/_forum-questions.scss";
@import "components/_input-error.scss";
@import "components/_input.scss";
@import "components/_label.scss";
@import "components/_labeled-input.scss";
@import "components/_link.scss";
@import "components/_modal.scss";
@import "components/_order-receipt.scss";
@import "components/_pagination.scss";
@import "components/_payment-icon.scss";
@import "components/_predicate-container.scss";
@import "components/_progress-bar.scss";
@import "components/_responsive-video.scss";
@import "components/_side-info.scss";
@import "components/_simple-panel.scss";
@import "components/_survey.scss";
@import "components/_tooltip.scss";
@import "components/_user-dropdown.scss";
@import "components/_video-navigator.scss";
@import "components/description-list.scss";

@import "layout/_centered-container.scss";
@import "layout/_central-block-page.scss";
@import "layout/_certificate.scss";
@import "layout/_contact-text.scss";
@import "layout/_course-content.scss";
@import "layout/_course-headline.scss";
@import "layout/_courses-list.scss";
@import "layout/_footer.scss";
@import "layout/_forum-questions.scss";
@import "layout/_header.scss";
@import "layout/_home-headline.scss";
@import "layout/_input-group.scss";
@import "layout/_main-nav.scss";
@import "layout/_no-data.scss";
@import "layout/_our-clients.scss";
@import "layout/_page-cta.scss";
@import "layout/_page-headline.scss";
@import "layout/_receipt.scss";
@import "layout/_sales-page-heading.scss";
@import "layout/_sales-page-section.scss";
@import "layout/_simple-page.scss";
@import "layout/_split-container.scss";

// Page specific styles (not reusable)
//@import "page/*";

// Utilities and helper classes with ability to override anything
// which goes before in the triangle, eg. hide helper class
@import "trumps/_nprogress.scss";
@import "trumps/_text.scss";

@import "healthcheck";
