.c-progress-bar {
    position: relative;

    height: 15px;
    width: 100%;
    border-radius: 2px;
    box-shadow: 0px 0px 2px $progress-bar-shadow-color;
    box-shadow: 0 2px 5px $progress-bar-shadow-color inset;

    background-color: $progress-bar-bgcolor;
}

.c-progress-bar__value {
    position: absolute;
    top: 0px;
    left: 0px;

    height: 100%;
    border-radius: 2px;

    background: $progress-bar-value-bgcolor1;
    @include prefixer-value(background,
        linear-gradient($progress-bar-value-bgcolor1, $progress-bar-value-bgcolor2), moz webkit ms o);
}
