.c-predicate-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: auto 0;
    overflow: hidden;
}

.c-predicate-container__item {
    margin: 8px 4px 0 4px;

    padding: 4px;

    display: flex;
    align-items: center;
    border-radius: 3px;
    height: 32px;
    overflow: hidden;
}

.c-predicate-container__item--filled {
    background-color: #4285f4;
    color: #fff;

    cursor: pointer;

    &::after {
        @extend .fa;
        position: relative;
        top: 0px;

        margin-right: 7px;

        font-size: 1.4em;
        /*vertical-align: sub;*/

        color: #fff;
        content: $fa-var-times-circle;
    }

    &:hover {
        background-color: darken(#4285f4, 10%);
    }
}

.c-predicate-container__item--default {
    background-color: #4285f4;
    color: #fff;

    cursor: pointer;
}

.c-predicate-container__item-content {
    margin: 0 7px 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.c-predicate-container__item--default .c-predicate-container__item-content {
    margin: 0 12px 0 12px;
}
