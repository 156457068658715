.c-responsive-video {
    box-sizing: border-box;
    margin: 0 auto;

    @include media($small) {
        width: 70%;
    }
}

.c-responsive-video__frame {
    box-sizing: border-box;
    border: 10px solid $responsive-video-border-color;
}
