.c-accordion {
    font-size: $accordion-font-size;
}

.c-accordion__tabs {
    @include clearfix;

    display: block;
    padding: 0 10px;
    border-bottom: 2px solid $accordion-tab-selected-bgcolor;

    list-style: none;
}

.c-accordion__tab {
    font-size: 0.8571em; // 12px

    @include media($small) {
        font-size: 1em; // 14px
    }

    a {
        float: left;

        padding: 10px;
        margin-right: 4px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;

        text-decoration: none;

        &:hover {
            background-color: $accordion-tab-hover-bgcolor;

            text-decoration: none;
        }
    }

    &.is-active a {
        background-color: $accordion-tab-selected-bgcolor;
        color: $accordion-tab-selected-text-color;
    }
}

.c-accordion__pane {
    display: none;

    &.is-active {
        display: block;
    }
}
