%heading {
    font-weight: 400;
}

.o-h1 {
    font-size: $heading1-font-size;

    @extend %heading;
}

.o-h2 {
    font-size: $heading2-font-size;

    @extend %heading;
}
