.c-input-error {
    display: none;
    margin-top: 6px;

    color: $error-color;

    font-size: 0.9em;
    text-align: left;
}

.c-input-error--visible {
    display: block !important;
}
