.l-course-content{
    margin-top: 35px;
    margin-bottom: 50px;

    text-align: left;
}

.l-course-content__video {
    position: relative;
    overflow: hidden;
}

.l-course-content__video {
    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.l-course-content__video-vdo {
    padding: 0 0 50px 0;
}

.l-course-content__video-panda {
    padding: 73px 0px 50% 0px;
}

.l-course-content__video-yt {
    padding: 147px 0px 50% 0px;
}

.l-course-content__video-vimeo {
    padding: 0 0 60% 0;
}

.l-course-content__actions {
    margin-bottom: 25px;
}

.l-course-content__links {
    margin-top: 15px;

    text-align: center;
}

.l-course-content__content {
    margin-top: 35px;
}

.l-course-content__content__actions {
    margin-top: 15px;
}
