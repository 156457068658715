.c-course-sales-curriculum {
    margin: 0;
    padding: 0;

    font-size: $course-sales-curriculum-font-size;

    list-style: none;

    counter-reset: chapter;
}

.c-course-sales-curriculum__chapter {
    @include clearfix;

    counter-increment: chapter;

    & + .c-course-sales-curriculum__chapter {
        margin-top: 30px;
    }
}

.c-course-sales-curriculum__chapter-name {
    margin: 0;

    font-size: 1.1em;
    font-weight: 500;

    color: $course-sales-curriculum-chapter-text-color;

    &::before {
        content: counters(chapter, ".") ". ";
    }
}

.c-course-sales-curriculum__lessons {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    border-top: 1px solid $course-sales-curriculum-border-color;

    list-style: none;

    font-size: 1em;
    font-weight: normal;

    color: $course-sales-curriculum-lesson-text-color;

    counter-reset: item;
}

.c-course-sales-curriculum__lesson {
    @include clearfix;

    margin-top: 10px;

    counter-increment: item;
}

.c-course-sales-curriculum__lesson-name {
    margin: 0;
    width: 80%;

    float: left;

    &::before {
        content: counters(chapter, ".") "." counters(item, ".") ". ";
    }
}

.c-course-sales-curriculum__lesson-duration {
    float: right;

    width: 20%;

    font-size: 0.9em;
    font-weight: 100;
    text-align: right;
}
