// Use _ variables only in setting files
$_brand-color: #020828;
$_brand-color-classic-2: #101BA0;
$_brand-color-classic: #1e94d2;

$_lighter-gray: #f7f7f7;
$_light-gray: darken($_lighter-gray, 3%);
$_normal-gray: darken($_lighter-gray, 13%);
$_dark-gray: darken($_lighter-gray, 55%);
$_darker-gray: darken($_lighter-gray, 80%);

$_brand-success: #1b8f06;
$_brand-warning: #dd732c;
$_brand-danger: #dc3545;
$_brand-secondary: #4285f4;
$_brand-info: #42e2f4;

$base-text-color: $_darker-gray;
$success-text-color:#155724;

$base-font-size: 1.4rem; // 14px
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

$heading1-font-size: 2.6rem;
$heading2-font-size: 2.2rem;
$heading3-font-size: 1.17rem;

$link-color: $_brand-color;

$error-color: #f96969;

// Neat
$gutter: 1em;

// Font Awesome
$fa-font-path: "../../dist/fonts";

// Tag code
$code-font-color: #e01e5a;
$code-bg-color: #f8f8f8;
$code-border-color: #ddd;

