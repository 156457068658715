.l-no-data {
    margin-top: 35px;
    margin-bottom: 35px;

    text-align: center;
}

.l-no-data__container {
    margin: 0 5%;
    padding: 35px;
    border-radius: 4px;

    background-color: $no-data-container-bgcolor;

    @include media($small) {
        margin: 0 20%;
    }
}

.l-no-data__text {
    font-size: $heading2-font-size;
    font-weight: normal;
}

.l-no-data__button {
    margin-top: 35px;
}
