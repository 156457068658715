@mixin container() {
    @include outer-container;

    padding: 0 15px;
    height: 100%;
}

@mixin container-text-optimized {
    @include container();

    @include media($small) {
        width: 75%;
    }
}

