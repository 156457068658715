// Button component
$button-text-color: #ffffff;
$button-login-bgcolor: $_brand-warning;
$button-login-hover-bgcolor: saturate($button-login-bgcolor, 15%);
$button-primary-bgcolor: $_brand-success;
$button-primary-hover-bgcolor: saturate($button-primary-bgcolor, 15%);
$button-warning-bgcolor: $_brand-warning;
$button-warning-hover-bgcolor: saturate($button-warning-bgcolor, 15%);
$button-danger-bgcolor: $_brand-danger;
$button-danger-hover-bgcolor: saturate($button-danger-bgcolor, 15%);
$button-neutral-bgcolor: $_darker-gray;
$button-neutral-hover-bgcolor: saturate($button-neutral-bgcolor, 15%);
$button-secondary-bgcolor: $_brand-secondary;
$button-secondary-hover-bgcolor: saturate($button-secondary-bgcolor, 15%);

$button-font-size: 1.4rem; // 14px
$button-big-font-size: 2rem; // 22px


// Checkout summary component
$checkout-summary-box-bgcolor: $_lighter-gray;
$checkout-summary-box-border-color: $_normal-gray;
$checkout-summary-box-text-color: $_dark-gray;
$checkout-summary-subtotal-box-price: $_dark-gray;
$checkout-summary-discount-box-price: $success-text-color;

$checkout-summary-font-size: 1.4rem; // 14px


// Course card component
$course-card-title-color: $_darker-gray;
$course-card-subtitle-color: $_lighter-gray;
$course-card-description-color: $_dark-gray;
$course-card-border-color: $_normal-gray;
$course-card-shadow-color: rgba($course-card-border-color, 0.9);
$course-card-annotation-bgcolor: rgba(0, 0, 0, 0.4);
$course-card-annotation-text-color: $_lighter-gray;
$course-card-ribbon-normal-color: #f70505;
$course-card-ribbon-dark-color: darken($course-card-ribbon-normal-color, 20%);
$course-card-extra-info-color: $_dark-gray;

$course-card-font-size: 1.3rem;


// Course sales curriculum component
$course-sales-curriculum-chapter-text-color: $_darker-gray;
$course-sales-curriculum-lesson-text-color: $_dark-gray;
$course-sales-curriculum-border-color: $_normal-gray;

$course-sales-curriculum-font-size: 1.6rem;


// Course instructor component
$course-instructor-name-text-color: $_darker-gray;
$course-instructor-bio-text-color: $_dark-gray;
$course-instructor-photo-border-color: $_normal-gray;

$course-instructor-font-size: 1.6rem;


// Fieldset component
$fieldset-legend-text-color: $_brand-color;

$fieldset-legend-font-size: 1.6rem; // 16px


// Input component
$input-text-color: $_dark-gray;
$input-border-color: $_normal-gray;

$input-font-size: 1.4rem;


// Payment icon component
$payment-icon-label-bgcolor: $_brand-color;


// Responsive video component
$responsive-video-border-color: $_light-gray;


// Side info component
$side-info-headline-color: $_brand-color;
$side-info-text-color: $_dark-gray;

$side-info-font-size: 1.4rem;


// Disclaimer component
$disclaimer-text-font-size: 1.2rem;




// Progress bar
$progress-bar-bgcolor: $_light-gray;
$progress-bar-shadow-color: rgba(0, 0, 0, 0.25);
$progress-bar-value-bgcolor1: $_brand-success;
$progress-bar-value-bgcolor2: darken($progress-bar-value-bgcolor1, 12%);


// Accordion
$accordion-font-size: 1.4rem;
$accordion-tab-selected-bgcolor: $_brand-color;
$accordion-tab-selected-text-color: #ffffff;
$accordion-tab-hover-bgcolor: $_light-gray;


// Course curriculum component
$course-curriculum-chapter-text-color: #ffffff;
$course-curriculum-chapter-bgcolor: $_dark-gray;

$course-curriculum-lesson-text-color: $base-text-color;
$course-curriculum-lesson-hover-bgcolor: $_lighter-gray;
$course-curriculum-lesson-current-bgcolor: $_light-gray;

$course-curriculum-border-color: $_normal-gray;

$course-curriculum-status-completed-color: $_brand-success;

$course-curriculum-font-size: 1.4rem;

// Accordion
$accordion-font-size: 1.4rem;
$accordion-tab-selected-bgcolor: $_brand-color;
$accordion-tab-selected-text-color: #ffffff;
$accordion-tab-hover-bgcolor: $_light-gray;


// Simple panel
$simple-panel-default-color: $_dark-gray;
$simple-panel-error-color: #e70500;

// Error panel
$error-panel-font-size: 1.4rem;
$error-panel-default-color: $_dark-gray;
$error-panel-code-color: $_normal-gray;


// Alert
$alert-font-size: 1.4rem;

$alert-error-bg-color: #f8d7da;
$alert-error-text-color: #721c24;
$alert-error-border-color: #f5c6cb;

$alert-success-bg-color: #d4edda;
$alert-success-text-color: #155724;
$alert-success-border-color: #c3e6cb;

$alert-info-bg-color: #fff3cd;
$alert-info-text-color: #856404;
$alert-info-border-color: #ffeeba;


// User dropdown
$user-dropdown-font-size: 1.4rem;
$user-dropdown-email-text-color: $_dark-gray;
$user-dropdown-border-color: $_normal-gray;
$user-dropdown-item-hover-bg-color: $_light-gray;
$user-dropdown-item-hover-text-color: $link-color;
$user-dropdown-item-text-color: $base-text-color;


// Forum questions
$forum-questions-font-size: 1.4rem;
$forum-questions-item-line-color: $_normal-gray;
$forum-questions-title-color: $base-text-color;
$forum-questions-title-hover-color: $link-color;
$forum-questions-author-date-color: $_dark-gray;
$forum-questions-vip-label-color: #fff;
$forum-questions-vip-label-bgcolor: $_darker-gray;
$forum-questions-label-color: #fff;
$forum-questions-label-bgcolor: #058205;
$forum-questions-label-secondary-color: #fff;
$forum-questions-label-secondary-bgcolor: $_brand-secondary;

// Forum message
$forum-message-font-size: 1.4rem;
$forum-message-item-line-color: $_normal-gray;
$forum-message-author-label-bg-color: #058205;
$forum-message-author-label-text-color: #fff;
$forum-message-author-date-color: $_dark-gray;
$forum-message-tutor-label-bg-color: $_brand-color;


// Order receipt
$order-receipt-font-size: 1.4rem;
$order-receipt-headline-success-color: $_brand-success;
$order-receipt-headline-fail-color: $error-color;
$order-receipt-headline-warning-color: $_brand-warning;
$order-receipt-headline-info-color: $_brand-info;
$order-receipt-box-bgcolor: $_lighter-gray;
$order-receipt-box-number-bgcolor: #fff;
$order-receipt-detail-border-color: $_dark-gray;
$order-receipt-detail-label-color: $_dark-gray;


// Pagination
$pagination-active-button-color: $_brand-color;
$pagination-inactive-button-color: $_lighter-gray;

// Forum dropdown
$dropdown-add-filter-link-color: $link-color;
$dropdown-add-filter-icon-color: $_brand-color;

//Big label
$big-label-text-color: #ffffff;
