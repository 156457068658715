.l-simple-page {
    margin-bottom: 30px;

    background-color: $simple-page-bg;
}

.l-simple-page__container {
    display: block;
    max-width: 420px;
    margin: auto;
    padding: 0 10px;
    padding-top: 10%;
}

.d-flex {
    display: flex;
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}
