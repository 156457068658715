.c-checkout-summary {
    margin-top: 15px;
}

.c-checkout-summary__box {
    padding: 15px;

    background-color: $checkout-summary-box-bgcolor;

    border: 1px solid $checkout-summary-box-border-color;
    border-radius: 4px;

    font-size: $checkout-summary-font-size;
}

.c-checkout-summary__box-description {
    display: block;
    padding-bottom: 15px;

    font-size: 1.1428em; // 16px
    font-weight: 500;

    border-bottom: 1px solid $checkout-summary-box-border-color;

    color: $checkout-summary-box-text-color;
}


.c-checkout-summary__box-content {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}

.c-checkout-summary__total-box-price {
    float: right;
    font-size: 1.5em;
    font-weight: 500;

    color: $checkout-summary-box-text-color;
}

.c-checkout-summary__total-box-text {
    font-size: 1.5em;
    font-weight: 500;

    color: $checkout-summary-box-text-color;
}

.c-checkout-summary__subtotal-box-price {
    float: right;
    font-size: 1.1em;
    font-weight: 500;

    color: $checkout-summary-subtotal-box-price;
}

.c-checkout-summary__subtotal-box-text {
    font-size: 1.1em;
    font-weight: 500;

    color: $checkout-summary-subtotal-box-price;
}

.c-checkout-summary__discount-box-price {
    float: right;
    font-size: 1.1em;
    font-weight: 500;

    color: $checkout-summary-discount-box-price;
}

.c-checkout-summary__discount-box-text {
    font-size: 1.1em;
    font-weight: 500;

    color: $checkout-summary-discount-box-price;
}

.c-checkout-summary__action {
    margin: 15px 0;
    text-align: center;
}

.c-checkout-summary__payment-icons {
    margin: 30px 0;
    text-align: center;
}
