.o-text {

    h1 {
        @extend .o-h1;
    }

    h2 {
        @extend .o-h2;
    }

    p + h1, p + h2, p + h3,
    ul + h1, ul + h2, ul + h3,
    pre + h1, pre + h2, pre + h3 {
        margin-top: 30px;
    }

    p {
        line-height: 1.6;
    }

    ul {
        @extend .o-list;
    }

    li {
        @extend .o-list__item;
    }

    img {
        @extend %responsive-img;
    }

}
