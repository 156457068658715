.c-course-instructor {
    @include clearfix;

    font-size: $course-instructor-font-size;

    & + .c-course-instructor {
        margin-top: 30px;
    }
}

.c-course-instructor__photo {
    display: block;
    margin: 0 auto;

    padding: 3px;
    border: 1px solid $course-instructor-photo-border-color;
    border-radius: 0.21em;

    @include media($small) {
        float: left;
        margin-right: 10px;
    }
}

.c-course-instructor__details {
}

.c-course-instructor__name {
    margin: 0;
    margin-bottom: 15px;
    margin-top: 15px;

    color: $course-instructor-name-text-color;

    font-size: 1.2em;
    font-weight: 400;
    text-align: center;

    @include media($small) {
        margin-top: 0;

        text-align: left;
    }
}

.c-course-instructor__bio {
    color: $course-instructor-bio-text-color;

    font-size: 1em;
}
