.l-footer {
    color: $footer-text-color;
    background-color: $footer-bgcolor;

    font-size: $footer-font-size;
}

.l-footer a {
    color: $footer-link-color;

    text-decoration: none;

    &:hover, :focus {
        color: $footer-link-hover-color;
    }
}

.l-footer__container {
    @include container;

    padding-top: 30px;
    padding-bottom: 30px;
}

.l-footer__legal {
    display: block;
    margin-top: 30px;

    font-size: 0.9230em; // 12px
    font-weight: 500;
    text-align: center;
}

.l-footer__copyright {
    display: block;
    margin-top: 15px;

    font-size: 0.8461em; //11px
    text-align: center;
}

.l-footer__columns {
    @include row();
}

.l-footer__column {
    @include media($extra-small-only) {
        text-align: center;
        margin-bottom: 30px;
    }
}

.l-footer__company-column {
    @extend .l-footer__column;

    @include media($small) {
        @include span-columns(3);
    }
}

.l-footer__social-column {
    @extend .l-footer__column;

    @include media($small-only) {
        @include span-columns(4);
    }

    @include media($medium) {
        @include span-columns(5);
    }
}

.l-footer__contact-column {
    @extend .l-footer__column;

    @include media($small-only) {
        @include span-columns(5);
    }

    @include media($medium) {
        @include span-columns(4);
    }
}

.l-footer__column-title {
    margin-bottom: 10px;
    line-height: 2;

    font-size: 1.4em;
    font-weight: 500;
}

.l-footer__menu-list {
    margin: 0;
    padding: 0;

    list-style: none;
}

.l-footer__menu-item {
    line-height: 2;
}

.l-footer__contact-list {
    padding: 0;
    margin: 0;

    font-size: 1.5em;

    list-style: none;
}

.l-footer__contact-item {
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0px;
    }

    &::before {
        @extend .fa;

        position: relative;

        width: 50px;

        font-size: 2em;
    }
}

.l-footer__contact-item--phone {
    &::before {
        top: 9px;

        content: $fa-var-phone;
    }
}

.l-footer__contact-item--whatsapp {
    &::before {
        top: 9px;

        content: $fa-var-whatsapp;
    }
}

.l-footer__contact-item--email {
    &::before {
        top: 6px;

        content: $fa-var-envelope-o;
    }
}

.l-footer__social-list {
    padding: 0;
    margin: 0;

    font-size: 1.5em;

    list-style: none;
}

.l-footer__social-media {
    display: inline-block;

    > a {
        font-size: 2.2em;
        margin-right: 5px;
    }

    > a::before {
        @extend .fa;
    }
}

.l-footer__social-media--facebook {
    > a::before {
        content: $fa-var-facebook-square;
    }
}

.l-footer__social-media--instagram {
    > a::before {
        content: $fa-var-instagram;
    }
}

.l-footer__social-media--twitter {
    > a::before {
        content: $fa-var-twitter-square;
    }
}

.l-footer__social-media--youtube {
    > a::before {
        content: $fa-var-youtube-square;
    }
}

.l-footer__social-media--linkedin {
    > a::before {
        content: $fa-var-linkedin-square;
    }
}

.l-footer__social-media--gplus {
    > a::before {
        content: $fa-var-google-plus-square;
    }
}
