.l-certificate {
    position: relative;

    display: block;
    height: 595px;
    width: 842px;

    text-align: center;
    font-size: 1.4rem;
}

.l-certificate__container {
    position: absolute;
    top: 248px;
    left: 50%;
    transform: translate(-50%);

    width: 100%;
    height: calc(100% - 248px);
    padding: 0 65px;

    color: #38231c;

    font-family: Times New Roman, serif;
}

.l-certificate__presentation {
    display: block;

    font-size: 1.3em;
    text-transform: uppercase;
}

.l-certificate__student-name {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 0 75px;
    overflow: hidden;

    font-family: "Great Vibes", cursive;
    font-weight: normal;
    font-size: 3em;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.l-certificate__description {
    display: block;
    height: 70px;
    padding: 0 20px;
    margin-top: 17px;

    font-size: 1.1em;
}

.l-certificate__issue-date {
    float: right;

    display: block;
    margin-right: 20px;
    margin-top: 15px;

    text-align: right;
}

.l-certificate__url {
    position: absolute;
    bottom: 55px;

    display: block;

    color: #38231c;

    font-size: 0.8em;
    text-align: left;

    &:hover {
        color: #38231c;
    }
}

.l-certificate__additional-info {
    padding: 0 18px;

    text-align: justify;

    @media print {
        display: none;
    }
}

.l-certificate__share-buttons {
    margin: 30px 0;

    @media print {
        display: none;
    }

    h2 {
        @extend .o-h2;
    }
}
