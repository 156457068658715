.o-user-message {
    line-height: 1.3;
    word-wrap: break-word;
    white-space: pre-line;

    p, h1, h2, h3, h4 {
        & > code {
            @include inline-code;
        }
    }
}
