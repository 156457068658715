.c-dropdown {
    padding-top: 10px;
    padding-left: 10px;

    width: 180px;
}

.c-dropdown__filter-link {
    color: $_dark-gray;

    &:hover {
        text-decoration: none;
        color: $link-color;
    }

    &::after {
        @extend .fa;
        position: relative;
        top: 0px;

        padding-top: 1px;
        padding-left: 2px;

        font-size: 1.0em;
        /*vertical-align: sub;*/

        color: $_dark-gray;
        content: $fa-var-filter;
    }
}

.c-dropdown__popup {
    display: none;
    z-index: 1600;
    position: absolute;


    background: #fff;
    border-radius: 3px;
    outline: none;
    margin-top: -15px;

    max-width: 265px;
    max-height: 400px;
    overflow-y: auto;

    min-width: 255px;

    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

    /*pointer-events: none;*/

    &.is-visible {
        transform: scale3d(1, 1, 1);
    }
}

.c-dropdown__menu-list {
    margin: 0;
    padding: 8px 0;
    width: 100%;
    /*border-top: 1px solid $user-dropdown-border-color;*/

    list-style: none;
}

.c-dropdown__menu-list__item {

    &.is-expanded > a {
        color: $user-dropdown-item-hover-text-color;
    }

    &.is-expanded > a::after {
        content: $fa-var-chevron-up;
    }

    a {
        display: block;
        padding: 8px 12px;

        color: $user-dropdown-item-text-color;

        text-decoration: none;

        &:hover {
            color: $user-dropdown-item-hover-text-color;
            background-color: $user-dropdown-item-hover-bg-color;

        }
    }

}

.c-dropdown__menu-list__sub-list {

    display: none;
    margin: 0;
    padding: 0;

    width: 100%;
    /*border-top: 1px solid $user-dropdown-border-color;*/

    list-style: none;

    @at-root .c-dropdown__menu-list__item.is-expanded & {
        display: block;
    }
}

.c-dropdown__menu-list__content {
    display: block;
    padding: 8px 12px;

    color: $user-dropdown-item-text-color;

    text-decoration: none;

    &:hover {
        color: $user-dropdown-item-hover-text-color;
        background-color: $user-dropdown-item-hover-bg-color;
        text-decoration: none;
    }

    @at-root .c-dropdown__menu-list__sub-item & {
        padding-left: 25px;
    }

    @at-root .c-dropdown__menu-list__item--group > &::after {
        @extend .fa;

        text-align: right;
        float: right;

        font-size: 1em;

        content: $fa-var-chevron-down;
    }
}
