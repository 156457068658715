.c-course-curriculum {
    margin: 0;
    padding: 0;

    font-size: $course-curriculum-font-size;

    list-style: none;
    counter-reset: chapter;
}

.c-course-curriculum__chapter {
    @include clearfix;

    counter-increment: chapter;

    & + .c-course-curriculum__chapter {
        margin-top: 15px;
    }
}

.c-course-curriculum__chapter-container {
    display: block;
    padding: 15px;

    color: $course-curriculum-chapter-text-color;
    background-color: $course-curriculum-chapter-bgcolor;

    cursor: pointer;

    &:hover, &:focus {
        text-decoration: none;
        background-color: darken($course-curriculum-chapter-bgcolor, 5%);
    }
}

.c-course-curriculum__chapter-name {
    margin: 0;

    font-size: 1.1em;
    font-weight: 500;
    text-decoration: none;

    &::before {
        content: counters(chapter, ".") ". ";
    }
}

.c-course-curriculum__lessons {
    display: none;
    margin: 0;
    padding: 0;

    list-style: none;
    counter-reset: item;
}

.c-course-curriculum__lesson {
}

.c-course-curriculum__lesson-container {
    @include clearfix;
    display: block;
    padding: 15px;
    border: 1px solid $course-curriculum-border-color;
    border-top: none;

    color: $course-curriculum-lesson-text-color;

    font-size: 1em;
    font-weight: normal;

    counter-increment: item;

    &:hover {
        background-color: $course-curriculum-lesson-hover-bgcolor;
    }

    .c-course-curriculum__lesson--current & {
        background-color: $course-curriculum-lesson-current-bgcolor;
    }
}

.c-course-curriculum__status {
    display: block;
    float: left;
    border: 2px solid $course-curriculum-border-color;
    border-radius: 50%;
    width: 15px;
    height: 15px;

    .c-course-curriculum__lesson--completed & {
        border-color: $course-curriculum-status-completed-color;
        background-color: $course-curriculum-status-completed-color;
    }
}


.c-course-curriculum__lesson-name {
    display: inline-block;
    width: calc(100% - 60px - 30px);
    margin: 0;
    margin-left: 15px;

    .c-course-curriculum__lesson--current & {
        font-weight: bold;
    }

    &::before {
        content: counters(chapter, ".") "." counters(item, ".") ". ";
    }
}

.c-course-curriculum__lesson-duration {
    float: right;
    width: 60px;

    font-size: 0.9em;
    font-weight: 100;
    text-align: right;
}
