.c-forum-message {
    padding: 20px 0;
    border-bottom: 2px solid $forum-message-item-line-color;

    font-size: $forum-message-font-size;

    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.c-forum-message__content {
}

.c-forum-message__answers-count {
    margin-top: 40px;

    font-size: 1.4em;
    font-weight: 400;
}

.c-forum-message__alert {
}

.c-forum-message__post-info {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.c-forum-message__author-avatar {
    border-radius: 50%;

    flex: 0 50px;
}

.c-forum-message__author-label {
    padding: 3px 5px;
    border-radius: 5px;

    background-color: $forum-message-author-label-bg-color;
    color: $forum-message-author-label-text-color;

    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

.c-forum-message__tutor-label {
    padding: 3px 5px;
    border-radius: 5px;

    background-color: $forum-message-tutor-label-bg-color;
    color: $forum-message-author-label-text-color;

    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

.c-forum-message__author-date {
    margin-left: 10px;
}

.c-forum-message__author-name {
    display: block;
}

.c-forum-message__post-date {
    display: block;
    margin-top: 2px;

    color: $forum-message-author-date-color;

    font-size: 0.9em;
}

.c-forum-message__title-input {
}

.c-forum-message__buttons {
    margin-top: 15px;
    margin-bottom: 30px;
}

.c-forum-message__link-action {
    padding-right: 2px;
}

.c-forum-message__sourcecode-shortcuts {
    text-align: right;
    margin-top: 15px;
}

.c-forum-message__embed-video {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    margin-top: 20px;
}

.c-forum-message__embed-video iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
