.l-main-nav {
    position: relative;

    display: flex;
    box-sizing: border-box;
    height: 100%;
    justify-content: space-between;
}

.l-main-nav__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
        height: 100%;
        vertical-align: middle;
    }
}

.l-main-nav__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;

}


.l-main-nav--cta-optimized {

    .l-main-nav__menu {
        display: none;

        @include media($small) {
            display: flex;
        }
    }

    .l-main-nav__logo {
        width: 100%;

        text-align: center;

        @include media($small) {
            width: auto;

            text-align: left;
        }
    }

}
