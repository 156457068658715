// Media
$medium-screen: 600px;
$large-screen: 768px;

.l-split-container {
    @include container;

    margin-bottom: 25px;
    margin-top: 25px;
}

.l-split-container__main {
    @include media($small) {
        @include span-columns(9);
    }
}

.l-split-container__main h1 {
    margin: 0;
    margin-bottom: 1em;
    margin-top: 1em;
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: 500;
    color: #1e94d2;
}

.l-split-container__main h2 {
    margin: 0;
    margin-bottom: 1em;
    font-size: 1.2em;
    font-weight: bold;
}

.l-split-container__side {
    @include media($small) {
        @include span-columns(3);

        border-left: 1px solid #ccc;
        padding-left: flex-gutter();
    }
}


.l-split-container__side__right {
    @include media($large-screen) {
        @include span-columns(3);

        float: right;
    }
}


.l-split-container__half {
    @include media($small) {
        @include span-columns(6);
    }
}
