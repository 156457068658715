.c-label {

}

.c-label--error {
    color: $error-color;
}

.c-label--grey {
    color: grey;
}

.c-big-label {
    padding: 1em 1.5em;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

    & .c-button__title {
        font-size: 1.4282em; // 14px * 1.4282 = 20px
        font-weight: 500;
        text-transform: uppercase;
    }

    & .c-button__description {
        font-size: 1em;
    }
}

.c-big-label--secondary {
    @extend .c-big-label;
    color: $big-label-text-color;
    background-color: $_brand-secondary;
}
