.c-alert {
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    display: block;

    font-size: $alert-font-size;
}

.c-alert--error {
    border-color: $alert-error-border-color;

    color: $alert-error-text-color;
    background-color: $alert-error-bg-color;
}

.c-alert--error-lg {
    border-color: $alert-error-border-color;
    padding: 1em;
    margin-bottom: 2em;
    text-align: center;
    color: $alert-error-text-color;
    background-color: $alert-error-bg-color;
}

.c-alert--success {
    border-color: $alert-success-border-color;

    color: $alert-success-text-color;
    background-color: $alert-success-bg-color;
}

.c-alert--info {
    border-color: $alert-info-border-color;

    color: $alert-info-text-color;
    background-color: $alert-info-bg-color;
}
