.c-button {
    display: inline-block;
    padding: 0.5em 1em;
    border-radius: 0.21em;
    border: none;

    color: $button-text-color;

    font-size: $button-font-size;
    font-weight: normal;
    text-decoration: none;

    transition: background-color 0.15s;
    cursor: pointer;

    &:hover, &:focus {
        text-decoration: none;
        color: $button-text-color;
    }

    -webkit-appearance: none;
}

.c-button__description {
    display: block;
    margin-top: 0.7em;

    font-size: 0.8em;
}

.c-button--disabled {
    opacity: .4;
    cursor: not-allowed;
}

.c-button--login {
    background-color: $button-login-bgcolor;

    &:hover, &:focus {
        background-color: $button-login-hover-bgcolor;
    }
}

.c-button--warning {
    background-color: $button-warning-bgcolor;

    &:hover, &:focus {
        background-color: $button-warning-hover-bgcolor;
    }
}

.c-button--warning-lg {
    padding: 1em 1.5em;
    background-color: $button-warning-bgcolor;

    &:hover, &:focus {
        background-color: $button-warning-hover-bgcolor;
    }
}

.c-button--danger {
    background-color: $button-danger-bgcolor;

    &:hover, &:focus {
        background-color: $button-danger-hover-bgcolor;
    }
}

.c-button--large {
    padding: 1em 1.5em;
}

.c-button--neutral {
    background-color: $button-neutral-bgcolor;

    &:hover, &:focus {
        background-color: $button-neutral-hover-bgcolor;
    }
}

.c-button--primary {
    padding: 1em 1.5em;

    background-color: $button-primary-bgcolor;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

    & .c-button__title {
        font-size: 1.4282em; // 14px * 1.4282 = 20px
        font-weight: 500;
        text-transform: uppercase;
    }

    & .c-button__description {
        font-size: 1em;
    }

    &:hover:not(:disabled), &:focus:not(:disabled) {
        background-color: $button-primary-hover-bgcolor;
    }
    &:disabled {
        background-color: grey;
    }
}

.c-button--secondary {
    @extend .c-button--primary;
    background-color: $button-secondary-bgcolor;
    &:hover:not(:disabled), &:focus:not(:disabled) {
        background-color: $button-secondary-hover-bgcolor;
    }
}

%c-button--upper {
    text-transform: uppercase;
}

.c-button--upper {
    text-transform: uppercase;
}

%c-button--big {
    font-size: $button-big-font-size;
}

%c-button--auto-width {
    width: 100%;

    @include media($small) {
        width: auto;
    }
}

.c-button--checkout-submit {
    @extend .c-button--primary;
    @extend %c-button--big;
    @extend %c-button--auto-width;
    @extend %c-button--upper;
}

.c-button--new-user-submit, .c-button--contact-submit,
.c-button--forum-message-submit, .c-button--order-receipt-navigation {
    @extend .c-button--primary;
    @extend %c-button--auto-width;
    @extend %c-button--upper;
}


.c-button--forum-message-submit:disabled {
    @extend .c-button--disabled;
    background-color: grey;
}

.c-button--forum-question-assign {
    @extend .c-button--secondary;
    @extend %c-button--auto-width;
}

.c-button--forum-question-unassign {
    padding: 1em 1.5em;
    @extend .c-button--danger;
    @extend %c-button--auto-width;
}

.c-button--forum-question-unassign:disabled {
    @extend .c-button--disabled;
    background-color: grey;
}

.c-button--form-login {
    @extend .c-button--primary;
    @extend %c-button--upper;

    width: 100%;
}

.c-button--purchase {
    @extend .c-button--primary;
}

.c-button--broader {
    padding: 1em 1.5em;
}

.c-button--simple {
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: none;
        color: $button-text-color;
    }
}

%c-button--icon {
    &::before {
        @extend .fa;

        position: relative;

        margin-right: 6px;

        font-size: 1.2em;
    }
}

.c-button--icon-trophy {
    @extend %c-button--icon;

    &::before {
        content: $fa-var-trophy;
    }
}

.c-button--icon-facebook {
    @extend %c-button--icon;

    &::before {
        content: $fa-var-facebook;
    }
}

.c-button--icon-twitter {
    @extend %c-button--icon;

    &::before {
        content: $fa-var-twitter;
    }
}

.c-button--icon-unchecked {
    @extend %c-button--icon;

    &::before {
        content: $fa-var-check-circle-o;
    }
}

.c-button--icon-checked {
    @extend %c-button--icon;

    &::before {
        content: $fa-var-check-circle;
    }
}

.d-none {
    display:none !important;
    //opacity: 0.2; /Para testes
}
