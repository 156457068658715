.l-home-headline {
    @include container;

    padding-top: 70px;
    padding-bottom: 70px;

    font-size: $home-headline-font-size;
    text-align: center;
}

.l-home-headline__title {
    margin: 0;
    margin-bottom: 20px;

    color: $home-headline-title-color;

    font-size: 1em;
    font-weight: 400;
}

.l-home-headline__subtitle {
    margin: 0;

    color: $home-headline-subtitle-color;

    font-size: 0.5em;
    font-weight: 300;
}
