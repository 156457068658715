.aw-error-panel {
    color: $error-panel-default-color;

    font-size: $error-panel-font-size;
    text-align: center;
}

.aw-error-panel__title {
    margin: 0;
    margin-top: 30px;

    font-size: 2.28em; // 32px
}

.aw-error-panel__description {
    display: block;
    padding: 18px 0;
}

.aw-error-panel__code {
    margin: 0;

    color: $error-panel-code-color;

    font-size: 5.71em; // 80px
    font-weight: normal;
}

.aw-error-panel__timestamp {
    margin-top: 10px;

    font-size: 0.7em;
}
