.c-side-info {
    font-size: $side-info-font-size;

    color: $side-info-text-color;

    h2 {
        margin: 0;
        margin-bottom: 1em;
        margin-top: 2em;

        text-transform: uppercase;
        font-size: 1.1428em;
        font-weight: 500;

        color: $side-info-headline-color;

        &:first-of-type {
            margin-top: 0
        }
    }

    p, img, li {
        margin: 1em 0;
    }

    img {
        @extend %responsive-img;

        margin-left: auto;
        margin-right: auto;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        padding: 4px 0px 0px 20px;

        background: url(/images/bullet-check.png) no-repeat left 3px;

        list-style-type: none;
    }

}
