$extra-small-only: new-breakpoint(max-width 767px);
$small-only: new-breakpoint(min-width 768px max-width 991px);
$medium-only: new-breakpoint(min-width 992px max-width 1199px);
$large-desktop-only: new-breakpoint(min-width 1200px);

$extra-small: $extra-small-only;
$small: new-breakpoint(min-width 768px);
$medium: new-breakpoint(min-width 992px);
$large-desktop: $large-desktop-only;

/*
$mobile-only: new-breakpoint(max-width 767px);
$tablet-only: new-breakpoint(min-width 768px max-width 991px);
$desktop-only: new-breakpoint(min-width 992px max-width 1199px);
$large-desktop-only: new-breakpoint(min-width 1200px);

$mobile: $mobile-only;
$tablet: new-breakpoint(min-width 768px);
$desktop: new-breakpoint(min-width 992px);
$large-desktop: $large-desktop-only;
*/



/*

$extra-small-only: new-breakpoint(max-width 575px);
$small-only: new-breakpoint(min-width 576px max-width 767px);
$medium-only: new-breakpoint(min-width 768px max-width 991px);
$large-only: new-breakpoint(min-width 992px max-width 1199px);
$extra-large-only: new-breakpoint(min-width 1200px);

$extra-small: $extra-small-only;
$small: new-breakpoint(min-width 576px);
$medium: new-breakpoint(min-width 768px);
$large: new-breakpoint(min-width 992px);
$extra-large: $extra-large-only;
*/
