.c-simple-panel {
    color: $simple-panel-default-color;

    text-align: center;
}

.c-simple-panel__message {
    padding: 20px 0px;

    &.is-error {
        color: $simple-panel-error-color;
    }
}

.c-simple-panel__box {
    padding: 20px;

    border: 1px solid #ccc;
    border-radius: 5px;

    background-color: #fff;
}

.c-simple-panel__footer {
    padding: 10px 0;
}
