.c-order-receipt {
    font-size: $order-receipt-font-size;
}

.c-order-receipt__headline {
    @extend .o-h1;

    text-align: center;

    &::before {
        @extend .fa;

        position: relative;
        top: 2px;

        margin-right: 10px;

        font-size: 2em;
        vertical-align: sub;
    }
}

.c-order-receipt__headline--success {
    color: $order-receipt-headline-success-color;

    &::before {
        content: $fa-var-check-circle;
    }
}

.c-order-receipt__headline--fail {
    color: $order-receipt-headline-fail-color;

    &::before {
        content: $fa-var-times-circle;
    }
}

.c-order-receipt__headline--warning {
    color: $order-receipt-headline-warning-color;

    &::before {
        content: $fa-var-exclamation-circle;
    }
}

.c-order-receipt__headline--info {
    color: $order-receipt-headline-info-color;

    &::before {
        content: $fa-var-exclamation-circle;
    }
}

.c-order-receipt__order-code-box {
    margin-top: 25px;
    padding: 30px;

    background-color: $order-receipt-box-bgcolor;

    text-align: center;
}

.c-order-receipt__order-code-box-title {
    display: block;
    margin-bottom: 20px;
}

.c-order-receipt__order-code-box-number {
    display: inline-block;
    padding: 10px;
    border-radius: 10px;

    background-color: $order-receipt-box-number-bgcolor;

    font-size: 1.4em;
    font-weight: 400;
    text-transform: uppercase;
}

.c-order-receipt__detail {
    margin: 25px 0;
    padding: 20px 0;
    border-top: 2px dashed $order-receipt-detail-border-color;
    border-bottom: 2px dashed $order-receipt-detail-border-color;
}

.c-order-receipt__field {
    margin-bottom: 15px;

    label {
        display: block;

        color: $order-receipt-detail-label-color;

        font-size: 0.8em;
        font-weight: bold;
        text-transform: uppercase;
    }

    &:last-child {
        margin-bottom: 0;
    }

}

.c-order-receipt__additional-info {
    @extend .o-text;

    text-align: justify;
}
